<template>
    <div>

        <div v-if="userData != null">
            <div class="d-flex">
                <feather-icon icon="InfoIcon" size="19" />
                <h4 class="mb-0 ml-50">Bank Information</h4>
            </div>

            <template v-if="userData.driver_information_bank != null">
                <template v-if="userData.driver_information_bank.length > 0">
                    <div v-for="info in userData.driver_information_bank.slice(0, 1)" :key="info.id">

                        <b-row class="mt-5">
                            <b-col md="4">
                                <b-form-group label="Type Account">
                                    <b-form-select id="gender" v-model="info.account" :options="optionAccountType">
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group label="Account number">
                                    <b-form-input v-model="info.bank_account" :maxlength="100" />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group label="Aba routing number">
                                    <b-form-input v-model="info.aba_routing" :maxlength="100" />
                                </b-form-group>
                            </b-col>

                            <b-col md="4">
                                <b-form-group label="Social security number">
                                    <b-form-input v-model="info.social_security_number" :maxlength="100" />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group label="Bank name">
                                    <b-form-input v-model="info.bank_institution" :maxlength="100" />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group label="Bank Address">
                                    <b-form-input v-model="info.address" :maxlength="250" />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group label="Bank City">
                                    <b-form-input v-model="info.city" :maxlength="50" />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group label="Bank State">
                                    <b-form-input v-model="info.state" :maxlength="50" />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group label="Bank zip code">
                                    <b-form-input v-model="info.zip" :maxlength="50" />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group label="Bank Telephone">
                                    <b-form-input v-model="info.telephone" :maxlength="10" />
                                </b-form-group>
                            </b-col>

                        </b-row>

                        <b-row>
                            <b-col md="12 save-btn">
                                <b-button variant="success" class="mt-2 mb-1 mb-sm-0 mr-0 mr-sm-1" type="submit"
                                    style="float: right;" @click="SaveData()">
                                    Update bank information
                                </b-button>
                            </b-col>
                        </b-row>

                    </div>
                </template>
            </template>


        </div>
    </div>
</template>

<script>
import {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormSelect,
    BFormRadio,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapGetters } from "vuex";
import { getMessageError } from "@core/utils/utils";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Cleave from 'vue-cleave-component'
import { getAccountBankType } from "@core/utils/menus";
require('cleave.js/dist/addons/cleave-phone.us');

export default {
    components: {
        BButton,
        BMedia,
        BAvatar,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BForm,
        BTable,
        BCard,
        BCardHeader,
        BCardTitle,
        BFormCheckbox,
        vSelect,
        BFormSelect,
        ValidationProvider,
        ValidationObserver,
        BFormRadio,
        Cleave,
    },
    data() {
        return {
            driverInfoBank: null,
            optionAccountType: [],
        };
    },
    computed: {
        ...mapGetters({
            userData: "Users/usersData",
        }),
    },
    methods: {
        SaveData() {
            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });
            let data = this.userData.driver_information_bank[0];
            this.$http
                .post(
                    `admin/panel/driver/update_driver_bank_information`,
                    data)
                .then((res) => {
                    this.$swal.close();
                    this.$swal({
                        title: res.data.message,
                        icon: "success",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });

                })
                .catch((error) => {
                    this.$swal({
                        title: getMessageError(error),
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });
        }

    },
    mounted() {
        this.optionAccountType = getAccountBankType();
    }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.text-name-driver {
    font-size: 1.5rem;
    margin-bottom: 0 !important;
}

.backgroundProfile-driver {
    background-color: $primary;
}

.blank_row {
    height: 50px !important;
    background-color: #ffffff;
}
</style>